<template>
    <div class="content-body">
        <div class="header">
            <div class="head-left">
                <span>分类</span>
                <el-select v-model="value" placeholder="请选择" @change="changeModule">
                    <el-option
                            v-for="item in options"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id">
                    </el-option>
                </el-select>
            </div>
            <el-button type="primary" @click="addOperationPromote"><i class="iconfont">&#xe609;</i>添加运营推广</el-button>
        </div>
        <el-table class="content-table customTable" :data="operationPromoteList"
                  style="width: 100%; margin-top: 20px; flex: 1;" height="1%" size="medium"
                  :header-cell-style="{fontWeight: 'normal', height: '60px', color: '#666666', background: '#F6F6F6',fontSize: '16px'}"
                  :cell-style="{fontSize: '16px',color: '#333333',height: '70px'}">
            <el-table-column prop="title" align="left" label="运营推广名称" width="200"></el-table-column>
            <el-table-column prop="module_id" align="center" label="分类"></el-table-column>
            <el-table-column align="center" label="操作" width="150">
                <template slot-scope="scope">
                    <el-link type="success" :underline="false" @click="editOperationPromote(scope.row.id)">编辑
                    </el-link>
                    <el-link type="danger" :underline="false" @click="delOperationPromote(scope.row.id)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination class="pager-center" style="margin: 20px 0;text-align: center"
                       :current-page="operationPromotePages.currentPageNum"
                       :page-size="operationPromotePages.eachPageNum"
                       :total="operationPromotePages.total"
                       layout="prev, pager, next, jumper"
                       @current-change="CurrentChange">
        </el-pagination>
    </div>
</template>

<script>
    import {operationPromote_getList, operationPromote_del} from '@/utils/apis'
    import {mapMutations} from "vuex";

    export default {
        name: "OperationPromote",
        data() {
            return {
                //运营推广列表
                operationPromoteList: [],
                //分页
                operationPromotePages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0,
                },
                value: 0,
                options: [
                    {
                        id: 0,
                        name: '全部'
                    },
                    {
                        id: 1,
                        name: '搜索引擎推广'
                    },
                    {
                        id: 2,
                        name: '信息流推广'
                    },
                    { id: 3, name: "国际搜索引擎推广"},
                    { id: 4, name: "海外社交媒体推广"},

                ],
            }
        },
        mounted() {
            this.getOperationPromoteList();
            localStorage.removeItem('isFirst')
        },
        methods: {
            ...mapMutations(['setOperationBasicData', 'setOperationStepTwoData']),
            //获取运营推广列表
            getOperationPromoteList() {
                let param = {
                    limit: this.operationPromotePages.eachPageNum,
                    page: this.operationPromotePages.currentPageNum,
                    type: 'page'
                }
                if (this.value != 0) {
                    param.module_id = this.value
                }
                operationPromote_getList(param).then((res) => {
                    this.operationPromoteList = res.data.list;
                    this.operationPromotePages.total = res.data.total;
                }).catch((err) => {
                    console.log(err);
                });
            },
            //添加运营推广
            addOperationPromote() {
                this.setOperationBasicData({})
                this.setOperationStepTwoData({})
                this.$router.push({
                    path: '/practice/operationPromote/create'
                })
            },
            //编辑
            editOperationPromote(id) {
                this.setOperationBasicData({})
                this.setOperationStepTwoData({})
                this.$router.push({
                    path: '/practice/operationPromote/create',
                    query: {
                        id: id
                    }
                })
            },
            //删除
            delOperationPromote(id) {
                this.$confirm(`是否删除该运营推广，删除将无法恢复`, '删除', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    customClass: 'green-message-box',
                    type: 'warning'
                }).then(() => {
                    operationPromote_del(id).then((res) => {
                        this.$message.success(res.msg);
                        this.getOperationPromoteList();
                    }).catch((err) => {
                        console.log(err);
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                })
            },
            //分页
            CurrentChange(val) {
                this.operationPromotePages.currentPageNum = val;
                this.getOperationPromoteList();
            },
            changeModule(val) {
                this.getOperationPromoteList()
            }
        }
    }
</script>

<style scoped lang="scss">
    .content-body {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
        padding: 0 20px;
        box-sizing: border-box;

        .header {
            display: flex;
            justify-content: space-between;

            .el-select {
                margin-left: 10px;
            }

            i {
                font-size: 12px;
                margin-right: 5px;
            }
        }

        .content-table {
            width: 100%;
            margin-top: 20px;
            flex: 1;
        }
    }
</style>
